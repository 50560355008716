import { axios, url } from "@/plugin/axios";

export default {
  getPointOrders() {
    return axios.get(`${url}point/admin/orders`);
  },

  getPoints() {
    return axios.get(`${url}point/admin/points`);
  },

  updatePointStatus(id) {
    return axios.patch(`${url}point/admin/orders/${id}`);
  },
};
