import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import product from "./modules/product";
import professional from "./modules/professional";
import handyman from "./modules/handyman";
import promotion from "./modules/promotion";
import dataStore from "./modules/dataStore";
import vendor from "./modules/vendor";
import user from "./modules/user";
import advert from "./modules/advert";
import commission from "./modules/commission";
import userLocation from "./modules/userLocation";
import realtor from "./modules/realtor";
import testimonial from "./modules/testimonial";
import affiliate from "./modules/affiliate";
import faq from "./modules/faq";
import pages from "./modules/pages";
import point from "./modules/point";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    dataStore,
    product,
    professional,
    handyman,
    vendor,
    promotion,
    user,
    advert,
    commission,
    userLocation,
    realtor,
    testimonial,
    affiliate,
    faq,
    pages,
    point,
  },
});
