import { axios, url } from "@/plugin/axios";

export default {
  createUser(data, type) {
    let path;
    switch (type) {
      case "Handyman":
        path = `${url}handymen/register`;
        break;
      case "Professional":
        path = `${url}professional/register`;
        break;
      case "Realtor":
        path = `${url}realtor/register`;
        break;
      case "Vendor":
        path = `${url}vendor/register`;
        break;
      default:
        path = `${url}admin/users/create`;

        break;
    }
    return axios.post(path, data);
  },
  updateUser(id, data) {
    return axios.patch(`${url}admin/users/update/${id}`, data);
  },
  users(data) {
    return axios.get(`${url}admin/users`, { params: data });
  },

  // Portfolios
  portfolios(data) {
    return axios.get(`${url}portfolios/admin`, { params: data });
  },

  updatePortfolioStatus({ approval_status, id }) {
    return axios.patch(`${url}portfolios/admin/update/${id}`, {
      approval_status,
    });
  },
  deletePortfolio(id) {
    return axios.delete(`${url}portfolios/admin/${id}`);
  },

  getUserInfo(id) {
    return axios.get(`${url}admin/users/edit/${id}`);
  },

  updateRoleInfo(id, data) {
    return axios.patch(`${url}admin/users/edit/roles/${id}`, {
      roles: data.roles,
      back_office_access: data.back_office_access,
    });
  },

  updatePasswordInfo(id, data) {
    return axios.patch(`${url}admin/users/edit/password/${id}`, data);
  },

  loginAsUser(id) {
    return axios.get(`${url}admin/users/login-as-user/${id}`);
  },
  getRoles() {
    return axios.get(`${url}admin/users/roles`);
  },
  getRole(id) {
    return axios.get(`${url}admin/users/roles/${id}/edit`);
  },
  getFormData() {
    return axios.get(`${url}admin/users/roles/show`);
  },
  getUFormData() {
    return axios.get(`${url}admin/users/form-data`);
  },
  createRole(data) {
    return axios.post(`${url}admin/users/roles`, data);
  },
  updateRole(id, data) {
    return axios.patch(`${url}admin/users/roles/${id}`, data);
  },

  createPermission(data) {
    return axios.post(`${url}admin/users/permissions`, data);
  },
  updatePermission(id, data) {
    return axios.patch(`${url}admin/users/permission/${id}`, data);
  },
  getPermissions(params) {
    return axios.get(`${url}admin/users/permissions`, { params });
  },
  getPermission(id) {
    return axios.get(`${url}admin/users/roles/${id}`);
  },
  deletePermissions(id) {
    return axios.delete(`${url}admin/users/permissions/${id}`);
  },
  buyers(data) {
    return axios.get(`${url}admin/users/buyers`, { params: data });
  },

  deleteUser(id) {
    return axios.delete(`${url}admin/users/${id}`);
  },
};
