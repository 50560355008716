import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("point");
import {
  FETCH_POINT_LOADING,
  FETCH_POINT_ORDER_SUCCESS,
  FETCH_POINT_FAILURE,
  FETCH_POINT_SUCCESS,
} from "./mutation_types";

const state = {
  points: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  orders: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },

  loading: false,
  errors: [],
};

const getters = {
  points(state) {
    return state.points;
  },
  orders(state) {
    return state.orders;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_POINT_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_POINT_ORDER_SUCCESS](state, data) {
    state.orders = data;
  },
  [FETCH_POINT_SUCCESS](state, data) {
    state.points = data;
  },
  [FETCH_POINT_FAILURE](state, data) {
    state.errors = data;
  },
};

const actions = {
  async getPointOrders({ commit }) {
    try {
      commit(FETCH_POINT_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getPointOrders();

      commit(FETCH_POINT_ORDER_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_POINT_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_POINT_FAILURE, false);
    }
  },
  async getPoints({ commit }) {
    try {
      commit(FETCH_POINT_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getPoints();

      commit(FETCH_POINT_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_POINT_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_POINT_FAILURE, false);
    }
  },
  async updatePointStatus({ commit }, data) {
    try {
      await PR.updatePointStatus(data.id);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
