// Users
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_LOADING = "FETCH_DATA_LOADING";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const FETCH_USERS_PERMISSIONS = "FETCH_USERS_PERMISSIONS";
export const FETCH_USERS_ROLES = "FETCH_USERS_ROLES";
export const PERMISSION_DELETED = "PERMISSION_DELETED";
export const ROLE_DELETED = "ROLE_DELETED";

// Product
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_LOADING = "FETCH_PRODUCTS_LOADING";
export const PRODUCTS_STATUS_CHANGED = "PRODUCTS_STATUS_CHANGED";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

// Order
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";
export const FETCH_ORDER_LOADING = "FETCH_ORDER_LOADING";

// proffesional
export const FETCH_PROFESSIONAL_SUCCESS = "FETCH_PROFESSIONAL_SUCCESS";
export const FETCH_PROFESSIONAL_FAILURE = "FETCH_PROFESSIONAL_FAILURE";
export const FETCH_PROFESSIONAL_LOADING = "FETCH_PROFESSIONAL_LOADING";
export const PROFESSIONAL_STATUS_CHANGED = "PROFESSIONAL_STATUS_CHANGED";

// proffesionalservices
export const FETCH_PSERVICES_SUCCESS = "FETCH_PSERVICES_SUCCESS";
export const FETCH_PSERVICES_FAILURE = "FETCH_PS_FAILURE";
export const FETCH_PSERVICES_LOADING = "FETCH_PS_LOADING";
export const FETCH_PRO_SERVICES_SUCCESS = "FETCH_PRO_SERVICES_SUCCESS";
export const FETCH_PRO_SERVICES_DELETED = "FETCH_PRO_SERVICES_DELETED";
// handymen management
export const FETCH_HANDYMEN_SUCCESS = "FETCH_HANDYMEN_SUCCESS";
export const FETCH_HANDYMEN_FAILURE = "FETCH_HANDYMEN_FAILURE";
export const FETCH_HANDYMEN_LOADING = "FETCH_HANDYMEN_LOADING";
export const HANDYMEN_STATUS_CHANGED = "HANDYMEN_STATUS_CHANGED";
export const FETCH_HANDYMEN_SERVICES_SUCCESS =
  "FETCH_HANDYMEN_SERVICES_SUCCESS";
export const FETCH_HANDYMEN_SERVICES_DELETED =
  "FETCH_HANDYMEN_SERVICES_DELETED";

export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_LOADING = "FETCH_ORDERS_LOADING";
export const PRODUCTS_ORDER_STATUS_CHANGED = "PRODUCTS_ORDER_STATUS_CHANGED";

// Transaction
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_LOADING = "FETCH_TRANSACTIONS_LOADING";

// Promotions
export const FETCH_PROMOTIONS_SUCCESS = "FETCH_PROMOTIONS_SUCCESS";
export const FETCH_PROMOTIONS_LOADING = "FETCH_PROMOTIONS_LOADING";
export const FETCH_PROMOTIONS_FAILURE = "FETCH_PROMOTIONS_FAILURE";

// vendors
export const FETCH_VENDOR_SUCCESS = "FETCH_VENDOR_SUCCESS";
export const FETCH_VENDOR_FAILURE = "FETCH_VENDOR_FAILURE";
export const FETCH_VENDOR_LOADING = "FETCH_VENDOR_LOADING";
export const VENDOR_STATUS_CHANGED = "VENDOR_STATUS_CHANGED";

// Users
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_LOADING = "FETCH_USERS_LOADING";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

// Users
export const FETCH_PORTFOLIOS_SUCCESS = "FETCH_PORTFOLIOS_SUCCESS";
export const FETCH_PORTFOLIOS_LOADING = "FETCH_PORTFOLIOS_LOADING";

// Tags
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_LOADING = "FETCH_TAGS_LOADING";

// Category
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_LOADING = "FETCH_CATEGORIES_LOADING";

// Attribute
export const FETCH_ATTRIBUTES_SUCCESS = "FETCH_ATTRIBUTES_SUCCESS";
export const FETCH_ATTRIBUTES_LOADING = "FETCH_ATTRIBUTES_LOADING";

// Advert
export const FETCH_ADVERTS_LOADING = "FETCH_ADVERTS_LOADING";
export const FETCH_ADVERTS_SUCCESS = "FETCH_ADVERTS_SUCCESS";
export const FETCH_ADVERTS_FAILURE = "FETCH_ADVERTS_FAILURE";

// Advert Sections
export const FETCH_ADVERT_SECTIONS_LOADING = "FETCH_ADVERT_SECTIONS_LOADING";
export const FETCH_ADVERT_SECTIONS_SUCCESS = "FETCH_ADVERT_SECTIONS_SUCCESS";

// Commission
export const FETCH_COMMISSION_LOADING = "FETCH_COMMISSION_LOADING";
export const FETCH_COMMISSION_SUCCESS = "FETCH_COMMISSION_SUCCESS";
export const FETCH_COMMISSION_FAILURE = "FETCH_COMMISSION_FAILURE";

// Users Locations
export const FETCH_USERLOCATION_LOADING = " FETCH_USERLOCATION_LOADING,";
export const FETCH_USERLOCATION_SUCCESS = "    FETCH_USERLOCATION_SUCCESS,";
export const FETCH_USERLOCATION_FAILURE = "    FETCH_USERLOCATION_FAILURE";

// Realtors
export const FETCH_REALTORS_SUCCESS = "FETCH_REALTORS_SUCCESS";
export const FETCH_REALTORS_LOADING = "FETCH_REALTORS_LOADING";
export const FETCH_R_FEATURES_SUCCESS = "FETCH_R_FEATURES_SUCCESS";
export const FETCH_R_FEATURES_LOADING = "FETCH_R_FEATURES_LOADING";
export const FETCH_R_TYPES_SUCCESS = "FETCH_R_TYPES_SUCCESS";
export const FETCH_R_TYPES_LOADING = "FETCH_R_TYPES_LOADING";
export const REALTORS_STATUS_CHANGED = "REALTORS_STATUS_CHANGED";
export const FETCH_PROPERTY_SUCCESS = "FETCH_PROPERTY_SUCCESS";
export const FETCH_PROPERTY_LOADING = "FETCH_PROPERTY_LOADING";
export const PROPERTY_STATUS_CHANGED = "PROPERTY_STATUS_CHANGED";

// Users Locations
export const FETCH_TESTIMONIAL_LOADING = " FETCH_TESTIMONIAL_LOADING,";
export const FETCH_TESTIMONIAL_SUCCESS = "    FETCH_TESTIMONIAL_SUCCESS,";
export const FETCH_TESTIMONIAL_FAILURE = "    FETCH_TESTIMONIAL_FAILURE";

// Affiliate
export const FETCH_AFFILIATE_LOADING = "FETCH_AFFILIATE_LOADING";
export const FETCH_AFFILIATE_SUCCESS = "FETCH_AFFILIATE_SUCCESS";
export const FETCH_AFFILIATE_FAILURE = "FETCH_AFFILIATE_FAILURE";
export const FETCH_AFFILIATE_DELETED = "FETCH_AFFILIATE_DELETED";
export const AFFILIATE_STATUS_CHANGED = "AFFILIATE_STATUS_CHANGED";

// Affiliate Sales
export const FETCH_AFFILIATE_SALES_SUCCESS = "FETCH_AFFILIATE_SALES_SUCCESS,";
export const FETCH_AFFILIATE_SALES_FAILURE = "FETCH_AFFILIATE_SALES_FAILURE";

// Affiliate Payouts
export const FETCH_AFFILIATE_PAYOUT_SUCCESS = "FETCH_AFFILIATE_PAYOUT_SUCCESS,";
export const FETCH_AFFILIATE_PAYOUT_FAILURE = "FETCH_AFFILIATE_PAYOUT_FAILURE";

// Faq
export const FETCH_FAQ_SUCCESS = "FETCH_FAQ_SUCCESS";
export const FETCH_FAQ_LOADING = "FETCH_FAQ_LOADING";
export const FETCH_FAQ_DELETED = "FETCH_FAQ_DELETED";
export const FETCH_FAQ_CATEGORY_SUCCESS = "FETCH_FAQ_CATEGORY_SUCCESS";
export const FETCH_FAQ_CATEGORY_DELETED = "FETCH_FAQ_CATEGORY_DELETED";

// Page
export const FETCH_PAGE_LOADING = "FETCH_PAGE_LOADING";
export const FETCH_PAGE_SUCCESS = "FETCH_PAGE_SUCCESS";
export const FETCH_PAGE_FAILURE = "FETCH_PAGE_FAILURE";
export const FETCH_PAGE_DELETED = "FETCH_PAGE_DELETED";
// Page Section
export const FETCH_PAGE_SECTION_SUCCESS = "FETCH_PAGE_SECTION_SUCCESS,";
export const FETCH_PAGE_SECTION_FAILURE = "FETCH_PAGE_SECTION_FAILURE";
export const FETCH_PAGE_SECTION_DELETED = "FETCH_PAGE_SECTION_DELETED";

export const FETCH_BUYERS_SUCCESS = "FETCH_BUYERS_SUCCESS";
export const FETCH_BUYERS_LOADING = "FETCH_BUYERS_LOADING";

export const FETCH_PAYOUT_ORDERS_SUCCESS = "FETCH_PAYOUT_ORDERS_SUCCESS";
export const ORDER_PAYMENT_APPROVED = "ORDER_PAYMENT_APPROVED";

// Users Locations
export const FETCH_POINT_LOADING = "FETCH_POINT_LOADING";
export const FETCH_POINT_ORDER_SUCCESS = "FETCH_POINT_ORDER_SUCCESS";
export const FETCH_POINT_FAILURE = "FETCH_POINT_FAILURE";
export const FETCH_POINT_SUCCESS = "FETCH_POINT_SUCCESS";
