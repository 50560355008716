import ApiRepository from "./ApiRepository";
import PromotionApiRepository from "./PromotionApiRepository";
import UserApiRepository from "./UserApiRepository";
import AdvertApiRepository from "./AdvertApiRepository";
import TestimonialApiRepository from "./TestimonialApiRepository";
import UserLocationApiRepository from "./UserLocationApiRepository";
import RealEstateApiRepository from "./RealEstateApiRepository";
import CommissionApiRepository from "./CommissionApiRepository";
import AffiliateApiRepository from "./AffiliateApiRepository";
import FaqApiRepository from "./FaqApiRepository";
import PageApiRepository from "./PageApiRepository";
import PointRepository from "./PointRepository";

const repositories = {
  request: ApiRepository,
  promotionRequest: PromotionApiRepository,
  userRequest: UserApiRepository,
  advertRequest: AdvertApiRepository,
  commission: CommissionApiRepository,
  userLocation: UserLocationApiRepository,
  realEstate: RealEstateApiRepository,
  testimonial: TestimonialApiRepository,
  affiliate: AffiliateApiRepository,
  faq: FaqApiRepository,
  page: PageApiRepository,
  point: PointRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
