import axios from "axios";
import store from "../store";

axios.interceptors.response.use((response) => response.data);
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// delete axios.headers.common['X-Requested-With'];

// AUTHORIZATION
const token = JSON.parse(localStorage.getItem("token"));

console.log(token, "token");

// const token = Vue.store.state
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${token}`;
  // config.headers.Accept =  `application/json`;
  return config;
});

let url =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_API_LIVE
    : process.env.VUE_APP_API_LOCAL;
// let url = process.env.VUE_APP_API_LIVE;
export { axios, url };
